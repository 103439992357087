@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.sliderItem {
  width: 100%;
  height: 56px;
  margin-bottom: 8px;
  background: $white;
  border-radius: 8px;
  border: 1px solid $gray_alt_bg;
  box-shadow: 0 3px 5px 1px $black_20;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  overflow: hidden;

  &.active {
    border: 1px solid $mantis;
    background-color: $mantis_20;

    .itemIndexInner {
      background-color: $picton_blue;
    }
  }

  .itemIndex {
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;

    &Inner {
      height: 28px;
      width: 28px;
      background: $brown;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      font-family: $roboto;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: $white;
    }
  }

  .shopName {
    padding-top: 4px;
    font-family: $roboto;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $black;
    margin-left: 6px;
  }
}
