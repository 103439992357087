@import "../../../../styles/mixins/background.scss";

.statusCell {
  .iconButtonDownload {
    margin-top: -2px;
  }
  :global {
    button {
      background-color: transparent;
    }
    .badge-warning {
      $color: #fe9400;
      @include background-opacity($color, 0.1);
      color: $color;

      &.badge-outlined {
        background-color: $color;
        color: white;
      }
    }

    .badge-success {
      $color: #8ac149;
      @include background-opacity($color, 0.1);
      color: $color;

      &.badge-outlined {
        background-color: $color;
        color: white;
      }
    }

    .badge-pill {
      border-radius: 1em;
    }

    .badge {
      font-weight: 400;
      font-size: 1em;
      padding: 0.5em 0.8em;
      text-transform: capitalize;
    }
  }
}
