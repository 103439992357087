@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.modal {
  max-width: initial;
  justify-content: center;

  .modalContent {
    width: auto;
    padding-left: 24px;
  }
}

.noActionsText {
  font-family: $roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
}
