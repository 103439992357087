@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.inputContainer {
  padding-left: 0;
}

.message {
  min-height: 19px;
  margin-left: -8px;
}

.text {
  font-family: $roboto;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: $black;
}

.asterisk {
  color: red;
}
