@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.title {
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-transform: capitalize;
  color: $black;
}

.infoContainer {
  margin: 0;
  margin-bottom: 25px;
  padding: 8px 16px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-left: 8px solid $pizazz;
  border-bottom: 1px solid $gray_alt_bg;

  .buttonContainer {
    button {
      font-weight: 400;
      font-size: 11px !important;
      line-height: 12px !important;
      text-transform: capitalize;
      color: $white;
      margin-left: 16px;
    }
  }
}

.pickupDetails {
  margin: 0;
  max-width: 75%;

  .pickupDetail {
    display: flex;
    align-items: center;
    margin-right: 35px;
    max-width: 60%;

    &__value {
      padding-top: 2px;
      margin-left: 6px;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.02em;
      text-transform: uppercase;
      color: $black;
    }
  }
}

.pickupDocumentsContainer {
  border-left: 8px solid $mantis;
  margin: 0;

  .pickupDocuments {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    > div {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.0025em;
      color: $black;
    }

    .dot {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: $black;
      margin-right: 10px;
      margin-top: -2px;
    }
  }

  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.0025em;
    color: $black;
  }
}

.modal {
  max-width: 500px;
  max-height: max-content;

  > div {
    border-radius: 8px;
    box-shadow: 0 24px 38px rgba(0, 0, 0, 0.14), 0 9px 46px rgba(0, 0, 0, 0.12),
      0 11px 15px rgba(0, 0, 0, 0.2);
    min-height: 12rem;
  }

  .header {
    display: flex;
    justify-content: space-between;
    font-family: $roboto;
    color: $black;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    padding-bottom: 20px;
    text-transform: capitalize;
  }

  .container {
    padding: 0.5rem 1rem;
  }

  .content {
    div.dayContainer {
      border: 1px solid $gray_alt_bg;
      display: flex;
      width: 100%;
      padding: 8px 16px;

      div.day {
        width: 50%;
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        color: $black;
      }

      div.time {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        color: $black;
      }
    }
  }

  .footer {
    border: none;

    button {
      margin-left: 12px;
    }
  }
}
