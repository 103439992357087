@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.issueStatus {
  font-size: 25px;
  line-height: 1.39;
  letter-spacing: -0.21px;
}

.reasonStatus {
  font-size: 14px;

  .errorMessage {
    display: block;
    font-weight: 700;
    color: $black;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
}

.subtext {
  font-size: 12px;
  font-style: italic;
}

.issueLogo {
  max-width: 300px;
  max-height: 60px;
  margin-right: 15px;
}
