@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.detail {
  display: flex;
  align-items: center;

  &__icon {
    margin-right: 10px;
  }

  &__text {
    font-family: $roboto;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: $black;
    margin: 3px 0 0;
  }
}
