@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.pickupShopDetails {
  height: 210px;

  .title {
    font-family: $roboto;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    text-transform: capitalize;
    color: $black;
    width: 100%;
    margin: 0;
    margin-bottom: 12px;
  }

  .addressContainer {
    font-family: $roboto;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    color: $black;

    > div {
      margin-top: 8px;
    }

    .copyToClipBoard {
      margin-top: 16px;
    }
  }
}

.separator {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.3);
  margin: 16px 0;
}

.details {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  height: 235px;
  overflow: auto;
}
