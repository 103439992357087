@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.title {
  padding: 16px 0;
}

.issueName {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: $red;
  margin-left: 0;
}

.extractPostcodes {
  margin-left: auto;
  padding-top: 20px;
}
