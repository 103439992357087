@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.tiles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 -15px;
}

.tile {
  display: flex;
  flex-direction: column;

  &__inner {
    flex-grow: 1;
    padding: 25px 25px 5px;
    background-color: $bright_grey;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    font-size: 14px;
    margin: 10px 0;
  }
}
