@import "~@dpdgroupuk/mydpd-ui/styles/themes";

.cc-modal-header {
  display: flex;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.0025em;
  color: $black_medium;
  padding-left: 0;
  margin-bottom: 10px !important;
}
