@import "~@dpdgroupuk/mydpd-ui/styles/variables";
@import "~@dpdgroupuk/mydpd-ui/styles/bootstrap";

.containerStyle {
  font-weight: 500;
  font-size: 16px;
  line-height: 200%;
  color: $black;
}

.textarea {
  max-height: 136px;
  resize: none;
}

.contactContainer {
  display: flex;
  gap: 1rem;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.item {
  width: 31%;
}

.tooltipContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 1rem;
  width: 36%;

  .item {
    width: 90%;
  }
}

$breakpoint: map-get($container-max-widths, md);

@media only screen and (max-width: $breakpoint) {
  .item,
  .tooltipContainer {
    width: 100%;
  }

  .contactContainer {
    flex-wrap: wrap;
  }
}
