@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 200%;
  color: $black;
}

.textarea {
  max-height: 136px;
  resize: none;
}
