.table {
  overflow-x: auto;

  .tableContent {
    max-height: 42vh;
  }
}

.extraButtons {
  padding: 0 8px;
}
