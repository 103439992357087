@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.container {
  color: $black;
}

.title {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-transform: capitalize;
}

.subtitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.0025em;
}

.desc {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.0025em;
  margin: 0;
  color: rgba(0, 0, 0, 0.67);
}
