@import "~@dpdgroupuk/mydpd-ui/styles";

.mydpd-tooltip {
  .tooltip-inner {
    border-radius: 8px;
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
  }
}

.icon-button {
  padding: 0;

  &:hover,
  &:active {
    path {
      stroke: none;
      fill: $ship_gray;

      &:focus {
        outline: 0;
      }
    }
  }

  &:focus {
    outline: 0;
  }
}
