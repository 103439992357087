@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.date {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.01em;
  text-align: left;
  color: $black;
}

.timetable {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.time {
  flex-grow: 1;
  text-align: right;
}
