@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.modal {
  max-width: 500px;
  max-height: max-content;

  > div {
    border-radius: 8px;
    box-shadow: 0 24px 38px rgba(0, 0, 0, 0.14), 0 9px 46px rgba(0, 0, 0, 0.12),
      0 11px 15px rgba(0, 0, 0, 0.2);
  }

  .container {
    padding: 0.5rem 1rem;
    padding-bottom: 0;
  }

  .icon {
    width: 49px;
    height: 49px;
  }

  .content {
    .header {
      margin-bottom: 5px;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      text-transform: capitalize;
      color: $black;
    }

    .message {
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: $black;
    }
  }

  .footer {
    border: none;
    padding-top: 0;

    button {
      margin-left: 12px;
    }
  }
}
