.statsCard {
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 992px) {
    flex-direction: column;

    div {
      padding-bottom: 10px;
    }
  }
}
