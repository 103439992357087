@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 10px;

  h6 {
    font-size: 14px;
  }

  .button {
    margin-top: 15px;
    margin-left: auto;
  }
}

.pickup {
  &__info {
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 14px;
    color: $red;
  }

  &__icon {
    width: 50px;
    margin-right: 20px;
  }

  &__notice {
    font-size: 12px;
  }
}

.pickupDetail {
  display: flex;
  align-items: center;
  margin: 5px 0;
  padding: 0;

  &__value {
    margin: 0;
    font-size: 14px;
  }

  &__icon {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
  }
}
