@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.serviceBannerContainer {
  padding: 0 !important;
}

.bannerColourRed {
  background-color: #d0012d !important;
}

.bannerColourBlue {
  background-color: #019bdf !important;
}

.bannerTitle {
  font-family: $pluto;
  font-weight: 370;
  font-size: 22px;
  line-height: 27px;
  color: $white;
}

.bannerContent {
  padding: 15px 24px;
}

.bannerButton {
  margin-top: 5px;
  font-family: $roboto;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: $white;
  background: $brown;
  padding: 5px 10px;
}

.bannerImg {
  width: 100%;
  height: 100%;
}
