@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.mydpd-image-view {
  justify-content: center;

  > div {
    width: 700px;
  }

  .carousel-item::before {
    -webkit-box-shadow: inset 0 0 20rem $black;
    box-shadow: inset 0 0 20rem $black;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
  }

  .carousel-item {
    width: 100%;
    height: 400px;
  }

  .image-helper {
    display: flex;
    height: 100%;
    justify-content: center;
  }

  .modal-header {
    padding-bottom: 9px;
  }

  .modal-body {
    padding-top: 0;
  }
}

.mydpd-one-image-view .modal-body .container:first-child {
  display: flex;
  justify-content: center;
}
