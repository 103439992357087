@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.container {
  margin-bottom: 24px;

  .status {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .trackingStatusText {
      font-family: $roboto;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: $black;
      margin-bottom: 16px;
    }
  }

  .infoContainer {
    align-items: flex-start;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
  }

  .customerLogo {
    height: 100%;
    max-width: 200px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;

    .customerLogoImg {
      max-width: 200px;
    }
  }
}
