@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.header {
  @media (max-width: 992px) {
    margin-bottom: 0 !important;
  }

  .titleText {
    font-weight: 500;
    color: $black;
    margin: 0;
  }
}
