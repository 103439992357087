@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.dayPickerContainer {
  .title {
    margin-bottom: 8px;
    font-family: $roboto;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    color: $black;
  }
}
