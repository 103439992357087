@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.markerSelected {
  z-index: 100 !important;
}

.mapPlaceholder {
  background-color: #e4e1d9;
  color: $ship_gray;
}
