@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.hiddenFields {
  visibility: hidden;
  opacity: 0;
  position: absolute;
}

.error {
  right: 0;
  height: 18px;
  font-size: 13px;
  color: $red;
}
