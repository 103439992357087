@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.textarea {
  max-height: 136px;
  resize: none;
}

.message {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: $black_high;
  margin-bottom: 26px;
}
