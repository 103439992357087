@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.electric {
  color: $forest_green;
  font-size: 15px;
  letter-spacing: 0.23px;
}

.infoContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1;
  margin-left: 0;

  .block {
    min-width: 50%;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    height: 50px;
    padding-left: 24px;

    > svg {
      width: 24px;
      height: 24px;
    }

    &.carInfo {
      border-left: 8px solid $mantis;
    }

    &.photoId {
      border-left: 8px solid $pizazz;
    }

    .message {
      margin-left: 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.0025em;
      color: #000;
    }
  }
}

.status {
  margin: 10px 0;
}
