@import "~normalize.css/normalize.css";
@import "~@dpdgroupuk/mydpd-ui/styles/index";
@import "~react-toggle/style.css";

.cursor-pointer {
  cursor: pointer;
}

.error-container {
  width: 257px;
  border: 0;
}

.redtext {
  color: $red;
}

.mydpd-modal {
  .modal-content {
    border: none;
  }
}
