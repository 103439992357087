@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.checkbox {
  display: block;
  position: relative;
  padding-left: 9px;
  margin-bottom: 12px;
  height: 18px;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  label {
    display: inline-block;
    cursor: pointer;
  }

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    /* Show the checkmark when checked */
    &:checked ~ .checkmark::after {
      display: block;
    }

    /* When the checkbox is checked*/
    &:checked ~ .checkmark {
      background-color: $picton_blue;
      border-color: $picton_blue;
    }
  }

  /* Style the checkmark/indicator */
  .checkmark::after {
    left: 4.5px;
    top: 0.5px;
    width: 4.5px;
    height: 11px;
    border: solid $white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  width: 1rem;
  height: 1rem;
  border: 1px solid $brown;
  border-radius: 2px;

  &Invalid {
    border-color: $red;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  &::after {
    content: "";
    position: absolute;
    display: none;
  }
}

.label {
  position: absolute;
  left: 1.5rem;
  font-size: 14px;
  font-style: italic;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: $black;
  white-space: nowrap;

  &Invalid {
    color: $red;
  }
}
