@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.error {
  height: 18px;
  font-size: 13px;
  color: $red;
  text-align: end;
  margin: 10px 0;
}

.submitButton {
  margin-left: 15px;
}
