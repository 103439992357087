@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.infoText {
  font-family: $roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.004em;
  color: $black_high;
  margin-bottom: 24px;
}
