@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.modal {
  min-width: 700px;

  > div {
    border-radius: 4px;
    width: 700px;
  }
}

.modalHeader {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: $black;
}

.img {
  object-fit: contain;
  max-height: 392px;
  width: 100%;
}

.modalFooter {
  border-top: none;
  display: flex;
  justify-content: space-between;
}
