@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.hidden {
  display: none;
}

.tileColumn {
  padding-bottom: 1.5rem;

  .tileContainer {
    margin: 0 8px 16px;

    &:last-child {
      margin-right: 0;
    }

    &:first-child {
      margin-left: 0;
    }

    .tileCard {
      margin: 0;

      &Active {
        border: 1px solid $mantis;
      }
    }

    .tileFooter {
      &Active {
        background-color: $mantis_20;
      }
    }

    .error {
      border: 1px solid $alert;
    }
  }
}

.errorMessage {
  font-family: $roboto;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.004em;
  color: $red;
}
