@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.errorContainer {
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.errorImg {
  width: 53px;
  margin-bottom: 30px;
}

.errorMessage {
  font-size: 26px;
  line-height: 30px;
  color: $black;
}
