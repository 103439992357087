@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.list {
  padding-left: 4px;
  font-style: normal;
  font-weight: normal;
  font-family: $roboto;
  font-size: 14px;
  line-height: 24px;
  color: $black_50;
}

.checkboxContainer {
  margin-top: 32px;

  .checkbox {
    margin: 0 0 10px -23px;
  }
}
