@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.container {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
  background-color: #6ed7f9;
  padding: 10px 0 10px 10px;
  margin-bottom: 10px;
  margin-top: -5px;
}

.text {
  margin-bottom: 0;
  font-family: $roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  margin-left: 10px;
}
