@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.error {
  color: $red;
  margin-left: 20px;
}

.inputContainer {
  max-width: 250px;
}

:global(.dropdown-menu.show) {
  box-sizing: content-box;
  width: auto;
}

.postcodeContainer {
  max-width: 377px;
}
